// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

// Swiper Slider
@import '~swiper/css';
@import '~swiper/css/navigation';
@import '~swiper/css/pagination';
@import '~swiper/css/autoplay';

// Sweet Alert2
@import '~sweetalert2/src/sweetalert2';